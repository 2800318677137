import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import './App.css'
import TagManager from 'react-gtm-module'

// Public Routes
import { Home } from "./pages/Home";
import { Sales } from "./pages/Sales";
import { ContactUs } from "./pages/ContactUs";
import { Investors } from "./pages/Investors";
import {Signup} from "./pages/Signup";
import {Login} from "./pages/Login";
import {ForgotPassword} from "./pages/ForgotPassword";
import Footer from './sharedcomponents/footer/Footer'
import TermsAndConditions from "./pages/Legal/TermsAndConditions";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import NotFound from "./pages/NotFound/NotFound";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";
import Team from "./pages/Team/Team";
import Logo from './assets/Logo.png';

// Private Routes
import {PaymentFailure} from "./pages/PaymentFailure";
import {PaymentSuccess} from "./pages/PaymentSuccess";
import {ConsentModal} from "./pages/ConsentModal";  // Import your ConsentBanner component
import {Profile} from "./pages/Profile";
import {SoftwareGuide} from "./pages/SoftwareGuide";
import {Helmet} from "react-helmet";
import { Blog, BlogPost } from "./pages/Blog";

import { AuthProvider } from "./contexts/AuthContext"
import PrivateRoute from "./sharedcomponents/PrivateRoute"

const App = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tagManagerInitialized, setTagManagerInitialized] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('userConsent');
    if (!consentGiven) {
      setIsModalVisible(true);
    } else if (consentGiven === 'true' && !tagManagerInitialized) {
      // initializeTagManager();
      console.log("tag manager initialized in index.html")
    }
  }, [tagManagerInitialized]);

  // Google Tag Manager - gives you the ability to add and update your own tags for conversion tracking, site analytics, remarketing, and more
  const initializeTagManager = () => {
    const tagManagerArgs = {
      gtmId: 'G-TP5JMP9M',
    };
    TagManager.initialize(tagManagerArgs);
    setTagManagerInitialized(true);
  };

  const handleAccept = () => {
    localStorage.setItem('userConsent', 'true');
    setIsModalVisible(false);
    // initializeTagManager();
    console.log("tag manager initialized in index.html")
  };

  const handleDecline = () => {
    localStorage.setItem('userConsent', 'false');
    setIsModalVisible(false);
  };

  return (
  <div className="bg-light">
      <Helmet>
        {/* Page Title */}
        <title>Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Tensor Pilot offers cutting-edge AI-driven drone software, enhancing precision flying, real-time object detection, and advanced analytics for professional drone pilots." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Tensor Pilot, AI Drone Software, Precision Flying, Real-Time Object Detection, Drone Analytics, Enterprise Drone Solutions, AI Drone Technology, Professional Drone Pilots, Tello Edu Drone, Tello Drone, Drone Education, Human Pose Detection, Face Mesh AI, Hand Gesture Recognition, Autonomous Drones, Drone Surveillance Systems, AI-Powered Drones, Cinematography Drones, Drone Software Upgrades, Remote Control Drones, Flying with Keyboard, Non-Commercial Drone Software, Drone AI Development, Drone Compatibility, Apple Drone Software, Windows Drone Software, Commercial Drone Applications, AI-Based Drone Analytics, Educational Drone Programs, Drone Integration, Drone Innovation, AI Drone Enhancements, Pro Drone Software, Drone Control Systems" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:description" content="Unlock the full potential of your drone with Tensor Pilot. Experience advanced AI features including object detection, precision flying, and in-depth analytics for unmatched drone performance." />
        <meta property="og:url" content="https://www.aidronesoftware.com/" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tensor Pilot - AI Drone Technology" />
        <meta name="twitter:description" content="Explore Tensor Pilot, the AI-driven drone software designed for precision, efficiency, and comprehensive data analysis." />
        <meta name="twitter:image" content={Logo} />

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <ConsentModal 
        show={isModalVisible} 
        handleAccept={handleAccept} 
        handleDecline={handleDecline} 
      />

      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/team" component={Team} /> */}
            <Route exact path="/sales" component={Sales} />          
            <Route exact path="/contactus" component={ContactUs} />
            {/* <Route exact path="/investors" component={Investors} /> */}
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/blog" component={Blog} />
            <Route path="/blog/:id" component={BlogPost} /> {/* Add this route */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            {/* <Route exact path="/softwareguide" component={SoftwareGuide} /> */}
            <PrivateRoute path="/paymentsuccess" component={PaymentSuccess} /> 
            <PrivateRoute path="/paymentfailure" component={PaymentFailure} /> 
            <PrivateRoute path="/profile" component={Profile} /> 
            <Route path="/terms-and-conditions" component={TermsAndConditions} /> 
            <Route path="/privacy-policy" component={PrivacyPolicy} /> 
            <Route path="/unsuscribe" component={Unsubscribe} /> 
            <Route path="/team" component={Team} /> 
            <Route component={NotFound} />
            {/* <PrivateRoute exact path="/downloads" component={Downloads} /> */}
          </Switch>
          <Footer />
        </AuthProvider>
      </Router>
  </div>
  );
}


export default App;
