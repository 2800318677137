// Team.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import Helmet from 'react-helmet';
import teamMembers from './teamData.js';
import './css/Team.css';

library.add(faLinkedin);

const Team = () => {
  return (
    <>
      <Helmet>
        <title>Team - AI Drone Software</title>
        <meta name="description" content="Meet the team behind the AI Drone Software." />
        <meta name="keywords" content="Drone AI Software, Drone Software, AI Drone" />
        <meta property="og:title" content="Team - AI Drone Software" />
        <meta
          property="og:description"
          name="description"
          className="swiftype"
          content="Meet the team behind the AI Drone Software."
        />
        <meta property="og:url" content="https://www.aidronesoftware.com/team" />
        <meta property="og:site_name" content="Team - AI Drone Software" />
      </Helmet>
      <NavBarMain />

      <Container className="team-container">
        <h2 className="text-center team-page-title mt-5 mb-5">Our Team</h2>
        <Row className="justify-content-center">
          {teamMembers.map((member) => (
            <Col key={member.id} md={4} sm={6} xs={12} className="mb-4">
              <Card className="team-card">
                <Card.Img variant="top" src={require(`./images/${member.photo}`)} alt={member.name} />
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{member.title}</Card.Subtitle>
                  <Card.Text>{member.bio}</Card.Text>
                  <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Team;
