// teamData.js
const teamMembers = [
  {
    id: 1,
    name: 'Piyush Garg',
    title: 'Co-Founder & Lead Engineer',
    bio: "A software engineer and innovator, transforms complex challenges into impactful solutions, leveraging cutting-edge technology to push the boundaries of what's possible.",
    photo: 'PiyushTP.jpeg',
    linkedin: 'https://www.linkedin.com/in/piyush888/'
  },
  {
    id: 2,
    name: 'KS',
    title: 'Co-Founder & Product Manager',
    bio: 'Focuses on creating practical tools and strategies that address real-world challenges and make a meaningful difference.',
    photo: 'BlankTP.png',
    linkedin: 'https://www.linkedin.com/'
  },
  {
    id: 2,
    name: 'Somil',
    title: 'Software Engineer',
    bio: 'Somil, our UI/UX lead, orchestrates user experience like a maestro. He blends client vision and user needs, crafting interfaces that are both beautiful and functional.',
    photo: 'BlankTP.png',
    linkedin: 'https://www.linkedin.com/'
  },
  ];

export default teamMembers;
